import * as React from 'react';
import { WFacebookCommentProps } from '../WFacebookComment.types';
import { useFacebookComment } from '../../../providers/useFacebook/useFacebook';
import styles from './styles/WFacebookComment.scss';

const WFacebookComment: React.FC<WFacebookCommentProps> = props => {
  const {
    id,
    colorScheme,
    language,
    numPosts,
    width,
    socialUrl = '',
    onMouseEnter,
    onMouseLeave,
  } = props;

  const ref = useFacebookComment({
    colorScheme,
    socialUrl,
    language,
    numPosts,
    width,
  });

  return (
    <div
      id={id}
      ref={ref}
      className={styles.root}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <div
        className="fb-comments"
        data-href={socialUrl}
        data-width={width}
        data-numposts={numPosts}
        data-colorscheme={colorScheme}
        data-mobile="false"
      />
    </div>
  );
};

export default WFacebookComment;
